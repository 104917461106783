<template>
  <div class="signupProcess">
    <el-dialog :visible.sync="detailsVisible" top="50px" custom-class="noticeDetailsBox" width="80%" :show-close="false"
    :close-on-click-modal="false" :close-on-press-escape="false">
        <div class="signupTitle">报名信息详情</div>

        <!-- 填写信息 -->
        <personalInformation ref="personalInformation" :id="examineId" :type="2" />
        
        <div class="closeBox">
          <el-button style="width:100px" @click="handleClose">关闭</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import personalInformation from '@/components/personalInformation'
export default {
  model:{
    prop:'detailsVisible',
    event:'change'
  },
  components:{
    personalInformation
  },
  props:['detailsVisible','examineId'],
  data() {
    return {
    }
  },
  mounted() {

  },
  methods:{

    // 关闭弹框
    handleClose(){
      this.$emit('change',false)
    }
  }
}
</script>
<style lang="scss" scoped>
  .signupProcess{
    .signupTitle{
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      color: #000;
    }
    

    .closeBox{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      padding: 10px 70px;
      border-top: 1px solid #DADADA;


      .closeBtn{
        min-width: 100px;
        height: 40px;
        background: rgb(189, 25, 44);
        border: none;
      }
    }
  }
</style>