<template>
  <div class="signupProcess">
    <el-dialog :visible.sync="processVisible" top="50px" custom-class="noticeDetailsBox" width="80%" :show-close="false"
    :close-on-click-modal="false" :close-on-press-escape="false">
        <div class="signupTitle">报名信息审核</div>

        <div class="examineBox">
          <div class="examineTitle">审核提示</div>
          <div>
            <span class="textColor">通过</span>————审核结论选择 “通过” ；<span class="textColor">不通过</span>————审核结论选择 “不通过” ；<span class="textColor">待完善</span>————勾选有问题的信息项，在审核说明中描述问题，审核结论选择 “待完善”
          </div>
        </div>

        <!-- 填写信息 -->
        <personalInformation :id="examineId" ref="personalInformation" :type="1" />

        <div class="closeBox">
          <el-button type="danger" class="closeBtn" :loading="loading" @click="submitFun">提交</el-button>
          <el-button style="width:100px" @click="handleClose">关闭</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import faithPromise from '@/components/faithPromise'
import personalInformation from '@/components/personalInformation'
import registration from '@/components/registration'
import {inspect} from '@/api/registrationReview/registrationReview'
export default {
  model:{
    prop:'processVisible',
    event:'change'
  },
  components:{
    faithPromise,
    personalInformation,
    registration
  },
  props:['processVisible','examineId'],
  data() {
    return {
      stepsList:[
        {
          name:'1.诚信承诺'
        },
        {
          name:'2.填写信息'
        },
        {
          name:'3.打印签字'
        },
        {
          name:'4.上传扫描件'
        },
        {
          name:'5.提交材料'
        },
        {
          name:'6.初步审核'
        }
      ],
      informationFlag:true,
      loading:false
    }
  },
  mounted() {
  },
  methods:{

    // 提交
    submitFun(){
      let {
        checkedObj,
        ruleForm,
        applyState
      } = this.$refs.personalInformation;
      // 问题项
      let arr = [
        {
          key: "checkedEmail",
          value: "电子邮件"
        },
        {
          key: "checkedName",
          value: "姓名",
        },
        {
          key: "checkedCard",
          value: "身份证号",
        },
        {
          key: "checkedBirthday",
          value: "出生日期",
        },
        {
          key: "checkedAge",
          value: "年龄",
        },
        {
          key: "checkedSex",
          value: "性别",
        },
        {
          key: "checkedCategory",
          value: "考生类别",
        },
        {
          key: "checkedSource",
          value: "生源地",
        },
        {
          key: "checkedRegister",
          value: "户口所在地",
        },
        {
          key: "checkedNativePlace",
          value: "籍贯",
        },
        {
          key: "checkedLandscape",
          value: "政治面貌",
        },
        {
          key: "checkedNation",
          value: "民族",
        },
        {
          key: "checkedPhone",
          value: "移动电话",
        },
        {
          key: "checkedPhoneBak",
          value: "备用电话",
        },
        {
          key: "checkedGraduateSchool",
          value: "最高学历毕业院校"
        },
        {
          key: "checkedGraduateDate",
          value: "最高学历毕业时间"
        },
        {
          key: "checkedEducationBackground",
          value: "最高学历"
        },
        {
          key: "checkedDiploma",
          value: "最高学位"
        },
        {
          key: "checkedDiplomaUnit",
          value: "最高学位授予单位"
        },
        {
          key: "checkedDiplomaTime",
          value: "最高学位授予时间"
        },
        {
          key: "checkedMajor",
          value: "最高学历所学专业"
        },
        {
          key: 'checkedEvaluation',
          value: "职称"
        },
        {
          key: "checkedEvaluationTime",
          value: "评聘时间"
        },
        // {
        //   key: "checkedGradeSchool",
        //   value: "本科毕业院校"
        // },
        // {
        //   key: "checkedGradeDate",
        //   value: "本科毕业时间"
        // },
        {
          key: "checkedGradeMajor",
          value: "本科所学专业"
        },
        {
          key: "checkedExperience",
          value: "教育经历",
        },
        {
          key: "checkedWorkExperience",
          value: "工作或实习经历"
        },
        {
          key: "checkedCommend",
          value: "个人简介",
        },
        {
          key: "checkedOtherIntroduce",
          value: "其他需要说明的问题",
        },
        {
          key: "checkedPersonal",
          value: "个人证件照",
        },
        {
          key: "checkedIdCard",
          value: "身份证扫描件",
        },
        {
          key: "checkedHousehold",
          value: "户口本扫描件",
        },
        {
          key: "checkedAcademic",
          value: "学历学位证明件",
        },
        {
          key: "checkedQualification",
          value: "应届生证明件",
        },
        {
          key: "checkedReturnees",
          value: "其他资格文件",
        },
        {
          key: "checkedApplication",
          value: "报名表扫描件",
        }
      ]
      let problemItem = '';
      arr.forEach(item=>{
        for (let key in checkedObj) {
          if (Object.hasOwnProperty.call(checkedObj, key)) {
            let element = checkedObj[key];
            if(key==item.key){
              if(element){
                problemItem+=item.value+'，'
              }
            }
          }
        }
      })
      problemItem = problemItem.substring(0,problemItem.length-1)
      if(!applyState){
        this.$message.error('审核结论不能为空!')
        return
      }
      let data = {
        problemItem,
        id:this.examineId,
        auditMsg:ruleForm.auditMsg,
        applyState:applyState
      }
      console.log(data);
      this.loading = true;
      inspect(data).then(res=>{
        if(res.status == 200){
          this.$message.success('提交成功！')
          this.$emit('update')
          this.handleClose()
        }else{
          this.$message.error(res.message)
        }
        this.loading = false;
      }).catch(error=>{
        this.loading = false;
      })
    },
    // 关闭弹框
    handleClose(){
      this.$emit('change',false)
    }
  }
}
</script>
<style lang="scss" scoped>
  .signupProcess{
    .signupTitle{
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      color: #000;
    }
    .examineBox{
      max-width: 932px;
      margin: 10px auto 30px;

      .examineTitle{
        color: #EB961D;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 10px;
      }
      .textColor{
        color: #EB961D;
      }
    }


    .closeBox{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      padding: 10px 70px;
      border-top: 1px solid #DADADA;


      .closeBtn{
        min-width: 100px;
        height: 40px;
        background: rgb(189, 25, 44);
        border: none;
      }
    }
  }
</style>
