<template>
  <div class="toExamineDiaBox">
    <el-dialog
      :visible.sync="dialogVisible"
      top="80px"
      custom-class="noticeDetailsBox"
      width="80%"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="toExamineHeader">
        <div class="searchBox">
          <ul>
            <li>
              <p>单位：</p>
              <el-select size="small" v-model="searchObj.unit" filterable placeholder="报考单位">
                <el-option
                  v-for="(item,index) in unitList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <!-- <el-input
                size="small"
                v-model="searchObj.unit"
                placeholder="报考单位"
              ></el-input> -->
            </li>
            <li>
              <p>岗位：</p>
              <el-select size="small" v-model="searchObj.positionName" filterable placeholder="报考岗位">
                <el-option
                  v-for="(item,index) in positionNameList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <!-- <el-input
                size="small"
                v-model="searchObj.positionName"
                placeholder="报考岗位"
              ></el-input> -->
            </li>
            <li>
              <p>姓名：</p>
              <el-input
                size="small"
                v-model="searchObj.name"
                placeholder="姓名"
              ></el-input>
            </li>
            <li>
              <p>身份证：</p>
              <el-input
                size="small"
                v-model="searchObj.idCard"
                placeholder="身份证"
              ></el-input>
            </li>
            <li>
              <p>状态：</p>
              <el-select
                size="small"
                class="width-100"
                v-model="searchObj.applyState"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </li>
          </ul>
          <div>
            <el-button size="small" type="warning" @click="searchFun">查询</el-button>
          </div>
        </div>

        <div style="margin: 10px 0;">
          <el-button size="middle" type="primary" @click="exportExcel">导出</el-button>
        </div>

        <div class="tableBox">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column
              align="center"
              prop="name"
              label="姓名"
              width="100"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              align="center"
              prop="idCard"
              label="身份证号"
              width="180"
            ></el-table-column>
            <el-table-column align="center" prop="sex" label="性别" width="60">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.sex == 1">男</span>
                  <span v-else>女</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="age"
              label="年龄"
              width="120"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="unit"
              label="报考单位"
              width="120"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="positionName"
              label="报考岗位"
              width="120"
            ></el-table-column>
            <el-table-column
              align="center"
              :formatter="formatterCategory"
              prop="examineeCategory"
              label="考生类别"
              width="200"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="examineeSource"
              label="生源"
              width="120"
            >
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.examineeSource == 1">京籍</span>
                  <span v-else>非京籍</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="phone"
              label="手机号"
              width="150"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="createTime"
              label="报名时间"
              width="180"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="updateTime"
              label="完善时间"
              width="180"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="applyState"
              :formatter="formatterFun"
              label="状态"
              width="120"
            ></el-table-column>
            <el-table-column align="center" prop="" label="操作" width="60">
              <template slot-scope="scope">
                <div>
                  <div
                    class="signUpColor"
                    @click="examineFun(scope.row)"
                    v-if="scope.row.applyState == 4"
                  >
                    审核
                  </div>
                  <div
                    class="signUpColor"
                    @click="seeDetailsFun(scope.row)"
                    v-if="
                      scope.row.applyState == 0 ||
                      scope.row.applyState == 1 ||
                      scope.row.applyState == 2
                    "
                  >
                    详情
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination">
            <el-pagination
              background
              @current-change="currentChange"
              layout="prev, pager, next"
              :page-size="10"
              :current-page="page"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="closeBox">
        <el-button style="width: 100px" @click="handleClose">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getDictValueListByDictType, getUnitAndPositionList } from "@/api/index";
import { getApplyAuditDetail } from "@/api/registrationReview/registrationReview";
export default {
  model: {
    prop: "dialogVisible",
    event: "change",
  },
  props: ["dialogVisible", "id"],
  data() {
    return {
      input: "",
      page: 1,
      total: 0,
      value: "",
      searchObj: {
        unit: '',
        positionName: '',
        name: '',
        idCard: '',
        applyState: ''
      },
      options: [
        {
          value: "",
          label: "不限状态",
        },
        {
          value: "4",
          label: "待审核",
        },
        {
          value: "2",
          label: "待完善材料",
        },
        {
          value: "0",
          label: "审核通过",
        },
        {
          value: "1",
          label: "审核不通过",
        },
      ],
      tableData: [],
      radioObj: null,
      examineeCategoryList: [],
      positionNameList: [], //岗位集合
      unitList: [], // 单位
    };
  },
  mounted() {
    this.getDictType();
    this.getData();
    this.getUnitAndPositionListFun()
  },
  methods: {
    getUnitAndPositionListFun(){
      let parm = {
        recruitId: this.id,
      }
      getUnitAndPositionList(parm).then(res=>{
        this.positionNameList = res.data.positionNameList
        this.unitList = res.data.unitNameList
      })
    },
    getDictType() {
      // 考生类别
      getDictValueListByDictType({
        dictType: "mct_base_examinee_category",
      }).then((res) => {
        if (res.status == 200) {
          this.examineeCategoryList = res.data;
        }
      });
    },
    searchFun() {
      this.page = 1;
      this.getData();
    },
    // 获列表
    getData() {
      let data = {
        recruitId: this.id,
        page: this.page,
        limit: 10,
        ...this.searchObj,
      };
      getApplyAuditDetail(data).then((res) => {
        if (res.status == 200) {
          this.tableData = res.data.rows;
          this.total = res.data.total;
        }
      });
    },
    // 导出
    exportExcel() {
      const url = `http://121.37.109.191:8765/api/pc/backstage/examinee/exportExcel`;
      window.open(`${url}?recruitId=${this.id}&unit=${this.searchObj.unit}&positionName=${this.searchObj.positionName}&name=${this.searchObj.name}&idCard=${this.searchObj.idCard}&applyState=${this.searchObj.applyState}`)
    },
    // 审核
    examineFun(row) {
      this.$emit("openAdit", { id: row.id, type: 1 });
    },
    seeDetailsFun(row) {
      this.$emit("openAdit", { id: row.id, type: 2 });
    },
    // 状态
    formatterFun(row) {
      let str = "";
      if (row.applyState == 4) {
        str = "待审核";
      }
      if (row.applyState == 2) {
        str = "待完善材料";
      }
      if (row.applyState == 0) {
        str = "审核通过";
      }
      if (row.applyState == 1) {
        str = "审核不通过";
      }
      return str;
    },
    // 考生类别
    formatterCategory(row) {
      let str = "";
      this.examineeCategoryList.forEach((item) => {
        if (item.dictDesc == row.examineeCategory) {
          str = item.dictValue;
        }
      });
      return str;
    },
    currentChange(val) {
      this.page = val;
      this.getData();
    },
    // 关闭弹框
    handleClose() {
      this.$emit("change", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.toExamineDiaBox {
  .toExamineHeader {
    padding: 0 30px;
    .toExamineTitle {
      font-size: 18px;
      font-weight: 550;
    }

    .searchBox {
      margin-top: 25px;
      display: flex;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          display: flex;
          align-items: center;
          margin: 0 25px 15px 0;
          width: 30%;

          p {
            width: 80px;
            text-align: right;
          }
        }
      }
    }
  }
  .signUpColor {
    color: rgb(17, 166, 234);
    cursor: pointer;
  }
  .closeBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 10px 70px;
    border-top: 1px solid #dadada;

    .closeBtn {
      width: 100px;
      height: 40px;
      background: rgb(189, 25, 44);
      border: none;
    }
  }
}
</style>
