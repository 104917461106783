<template>
  <div class="registrationReviewBox">
    <div class="searchBox">
      <div class="searchLeft">
        <ul>
          <li>
            <p class="searchTitle">招聘事项：</p>
            <el-input v-model="title" placeholder="请输入招聘事项"></el-input>
          </li>
          <li>
            <el-button class="searchBtn" type="warning" @click="searchFun">查询</el-button>
          </li>
        </ul>
      </div>
    </div>

    <div class="registrationReviewContent">
      <div class="tableBox">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
          <el-table-column align="center" prop="title" label="招聘事项" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="time" label="报名时间" width="280"></el-table-column>
          <el-table-column align="center" :formatter="formatterFun" prop="date" label="审核状态" width="100"></el-table-column>
          <el-table-column align="center" prop="applyCount" label="已报" width="80"></el-table-column>
          <el-table-column align="center" prop="waitCount" label="待审" width="80"></el-table-column>
          <el-table-column align="center" label="操作" width="130">
            <template slot-scope="scope">
              <div class="signUpFlex">
                <p class="signUpColor" v-if="scope.row.checkState==0" @click="getIntoExamine(scope.row)">进入审核</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          background
          @current-change="currentChange"
          layout="prev, pager, next"
          :page-size="10"
          :current-page="page"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 进入审核 -->
    <toExamineDia ref="examine" v-if="dialogVisible" :id="id" @openAdit='openAdit' v-model="dialogVisible" />

    <!-- 审核 -->
    <examineDia @update="update" v-if="processVisible" :examineId="examineId" v-model="processVisible" />

    <!-- 查看详情 -->
    <examineDetails v-if="detailsVisible" :examineId="examineId" v-model="detailsVisible" />
  </div>
</template>
<script>
import toExamineDia from '@/views/registrationReview/toExamineDia.vue'
import examineDia from '@/views/registrationReview/examineDia.vue'
import examineDetails from '@/components/examineDetails.vue'
import {AuditIndex} from '@/api/registrationReview/registrationReview'
export default {
  components:{
    toExamineDia,
    examineDia,
    examineDetails
  },
  data() {
    return {
      title:'',
      page:1,
      total:0,
      dialogVisible:false,
      processVisible:false,
      detailsVisible:false,
      tableData:[],
      examineId:'',
      id:''
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    update() {
      this.$refs.examine.getData();
    },
    // 查询
    searchFun(){
      this.page = 1;
      this.getData()
    },
    // 获取列表
    getData(){
      let data = {
        page:this.page,
        limit:10,
        title:this.title
      }
      AuditIndex(data).then(res=>{
        if(res.status == 200){
          if(res.data.rows&&res.data.rows.length){
            this.tableData = res.data.rows.map(item=>{
              if(item.beginTime&&item.endTime){
                let bIndex = item.beginTime.lastIndexOf(':');
                let bigin = item.beginTime.substring(0,bIndex).replace(/-/g,'.');
                let eIndex = item.endTime.lastIndexOf(':');
                let end = item.endTime.substring(0,eIndex).replace(/-/g,'.');
                item.time = bigin+' ~ '+end
              }
              return item
            });
          }else{
            this.tableData = [];
          }
          this.total = res.data.total;
        }
      })
    },
    // 进入审核
    getIntoExamine(row){
      this.id = row.id;
      this.dialogVisible = true;
    },
    // 打开审核
    openAdit(obj){
      // this.dialogVisible = false;
      if(obj.type==1){
        this.processVisible = true;
      }else{
        this.detailsVisible = true;
      }
      this.examineId = obj.id;
    },
    // 状态
    formatterFun(row){
      let str = '';
      if(row.checkState==0){
        str = '开启'
      }
      if(row.checkState==1){
        str = '关闭'
      }
      return str
    },
    currentChange(val){
      this.page = val;
      thia.getData()
    }
  }
}
</script>
<style lang="scss" scoped>
  .registrationReviewBox{
    .searchBox{
      display: flex;
      justify-content: space-between;
      padding: 20px;
      .searchLeft{
        ul{
          display: flex;
          li{
            display: flex;
            align-items: center;
            margin-right: 30px;

            .searchTitle{
              min-width: 80px;
              text-align: right;
            }
            .searchStatus{
              min-width: 50px;
              text-align: right;
            }
          }
        }
      }

      .searchRight{
        .addBtn{
          background: #bf162a;
          color: #fff;
          border: none;
        }
      }
    }

    .registrationReviewContent{
      margin-top: 20px;
      .signUpFlex{
        display: flex;
        justify-content: center;
        .signUpColor{
          color: rgb(17, 166, 234);
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }
  }
</style>
