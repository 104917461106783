import axios from '@/utils/axios'

// 获取审核列表
export function AuditIndex(params){
  return axios({
    url:'/api/pc/backstage/recruit/AuditIndex',
    method:'get',
    params
  })
}

// 进入审核后获取审核列表
export function getApplyAuditDetail(params){
  return axios({
    url:'/api/pc/backstage/examinee/getApplyAuditDetail',
    method:'get',
    params
  })
}


// 审核提交
export function inspect(data){
  return axios({
    url:'/api/pc/backstage/examinee/inspect',
    method:'post',
    data
  })
}